<template>
	<div>
  

	</div>
</template>

<script>
import { BCard, BCardText, BLink,BImg,BOverlay,BSpinner } from 'bootstrap-vue'
import VStatsBox from '@/components/VStatsBox.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
    components: {
        BCard,
        BCardText,
        BLink,
        BImg,
        VStatsBox,
        VuePerfectScrollbar,
        BOverlay,
        BSpinner
    },
    data() {
        return {
            
        }
    },
    mounted(){
       
    },
    methods:{
        
    },

}
</script>

<style>

</style>
